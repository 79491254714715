<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="[afterDedupDailySeries, preDedupDailySeries, globalDedupFactorDailySeries, localCompressionFactorDailySeries, overallDedupFactorDailySeries]"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import moment from '@/libs/moment'
import DataDomainChartMixin from './DataDomainChartMixin'

export default {
  mixins: [DataDomainChartMixin],
  data() {
    return {
      isRendered: false,
      requestIncludedFields: [
        'H24_Post',
        'H24_Pre',
        'H24_Gc',
        'H24_Lc',
      ],
      chartOptions: {
        chart: {
          id: 'dailyCapacityPreAndPostDedupChart',
          group: this.$props.chartGroup,
          type: 'line',
          height: 350,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          show: true,
          curve: 'straight',
          lineCap: 'butt',
          width: 1.5,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'Daily capacity pre and post deduplication - Deduplication ratio',
          align: 'left',
        },
        yaxis: [
          {
            seriesName: 'Post dedup',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} GiB`
              },
            },
            title: {
              text: 'Capacity Post dedup',
            },
            minWidth: 0,
          },
          {
            seriesName: 'Pre dedup',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)} TiB`
              },
            },
            title: {
              text: 'Capacity Pre dedup',
            },
            minWidth: 0,
          },
          {
            seriesName: 'Global dedup',
            labels: {
              formatter(val) {
                return `${(val).toFixed(0)}`
              },
            },
            title: {
              text: 'Factor',
            },
            minWidth: 0,
            opposite: true,
          },
          {
            seriesName: 'Global dedup',
            show: false,
          },
          {
            seriesName: 'Global dedup',
            show: false,
          },
        ],
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (config.seriesIndex === 0) {
                if (val) {
                  return `${(val).toFixed(0)} GiB`
                }
              } else if (config.seriesIndex === 1) {
                if (val) {
                  return `${(val).toFixed(0)} TiB`
                }
              }

              if (val) {
                return `${(val).toFixed(2)}`
              }

              return null
            },
          },
          x: {
            formatter(val) {
              return moment.utc(val).format('L')
            },
          },
        },
      },
    }
  },
  computed: {
    afterDedupDailySeries() {
      return {
        name: 'Post dedup',
        type: 'column',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.h24_Post / 1024 / 1024 / 1024]),
      }
    },
    preDedupDailySeries() {
      return {
        name: 'Pre dedup',
        type: 'column',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.h24_Pre / 1024 / 1024 / 1024 / 1024]),
      }
    },
    globalDedupFactorDailySeries() {
      return {
        name: 'Global dedup',
        type: 'line',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.h24_Gc]),
      }
    },
    localCompressionFactorDailySeries() {
      return {
        name: 'Local compression',
        type: 'line',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.h24_Lc]),
      }
    },
    overallDedupFactorDailySeries() {
      return {
        name: 'Overall dedup factor',
        type: 'line',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), (!x.h24_Lc || !x.h24_Gc ? null : x.h24_Lc * x.h24_Gc)]),
      }
    },
  },
}

</script>
