<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last90Days', 'last180Days', 'last365Days', 'last2Years', 'last3Years', 'last5Years']"
                :max-selectable-days="2000"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="compactMode"
              >
                Compact mode
              </b-form-checkbox>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <DataUsagePercentageChart
        chart-group="capacity"
        :chart-height-normal-mode="chartHeightNormalMode"
        :chart-height-compact-mode="chartHeightCompactMode"
        :statistic="statistic"
        :data-loaded="statisticDataLoaded"
        :compact-mode="compactMode"
        :time-range="timeRange"
        :standalone="false"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card>
      <DailyCapacityPreAndPostDedupChart
        chart-group="capacity"
        :chart-height-normal-mode="chartHeightNormalMode"
        :chart-height-compact-mode="chartHeightCompactMode"
        :statistic="statistic"
        :data-loaded="statisticDataLoaded"
        :compact-mode="compactMode"
        :time-range="timeRange"
        :standalone="false"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card>
      <WeeklyCapacityPreAndPostDedupChart
        chart-group="capacity"
        :chart-height-normal-mode="chartHeightNormalMode"
        :chart-height-compact-mode="chartHeightCompactMode"
        :statistic="statistic"
        :data-loaded="statisticDataLoaded"
        :compact-mode="compactMode"
        :time-range="timeRange"
        :standalone="false"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card>
      <DataUsagePreAndPostOptimizationChart
        chart-group="capacity"
        :chart-height-normal-mode="chartHeightNormalMode"
        :chart-height-compact-mode="chartHeightCompactMode"
        :statistic="statistic"
        :data-loaded="statisticDataLoaded"
        :compact-mode="compactMode"
        :time-range="timeRange"
        :standalone="false"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card>
      <DataOptimizationFactorOverTimeChart
        chart-group="capacity"
        :chart-height-normal-mode="chartHeightNormalMode"
        :chart-height-compact-mode="chartHeightCompactMode"
        :statistic="statistic"
        :data-loaded="statisticDataLoaded"
        :compact-mode="compactMode"
        :time-range="timeRange"
        :standalone="false"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card>
      <PhysicalCapacityChart
        chart-group="capacity"
        :chart-height-normal-mode="chartHeightNormalMode"
        :chart-height-compact-mode="chartHeightCompactMode"
        :statistic="statistic"
        :data-loaded="statisticDataLoaded"
        :compact-mode="compactMode"
        :time-range="timeRange"
        :standalone="false"
        :timezone="asup.timezone"
      />
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox, VBPopover, BForm, BFormGroup,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import DataDomainService from '@/service/datadomain.service'
import moment from '@/libs/moment'

import DataUsagePercentageChart from './charts/DataUsagePercentageChartView.vue'
import DailyCapacityPreAndPostDedupChart from './charts/DailyCapacityPreAndPostDedupChartView.vue'
import WeeklyCapacityPreAndPostDedupChart from './charts/WeeklyCapacityPreAndPostDedupChartView.vue'
import DataUsagePreAndPostOptimizationChart from './charts/DataUsagePreAndPostOptimizationChartView.vue'
import DataOptimizationFactorOverTimeChart from './charts/DataOptimizationFactorOverTimeChartView.vue'
import PhysicalCapacityChart from './charts/PhysicalCapacityChartView.vue'

export default {
  components: {
    DateRangePicker,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    DataUsagePercentageChart,
    DailyCapacityPreAndPostDedupChart,
    WeeklyCapacityPreAndPostDedupChart,
    DataUsagePreAndPostOptimizationChart,
    DataOptimizationFactorOverTimeChart,
    PhysicalCapacityChart,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compactMode: false,
      chartHeightNormalMode: 350,
      chartHeightCompactMode: 150,
      timeRange: {
        range: 'last14Days',
      },
      statistic: [],
      statisticDataLoaded: false,
    }
  },
  watch: {
    timeRange(val) {
      this.loadStatistic(val)
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      const includeFields = [
        'UsedInPercent',
        'PreCompBytes',
        'UsedBytes',
        'Factor',
        'H24_Gc',
        'H24_Lc',
        'H24_Post',
        'H24_Pre',
        'T7_Gc',
        'T7_Lc',
        'T7_Post',
        'T7_Pre',
        'CapacityBytes',
      ]
      /*
        Performance data is stored in child list of statistic data. Performance data of e.g. yesterday may be contained in statistics of today.
        So we need to query statistic data one day before and after the range
        */
      DataDomainService.getAllStatisticListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: moment(this.timeRange.endDate).add(1, 'days').endOf('day').toISOString(),
        include: includeFields,
        maxResultCount: 1000,
      }, { disableTenantFilter: true })
        .then(result => {
          this.statistic = []
          const processedDays = {}
          for (let i = 0; i < result.length; i += 1) {
            const day = moment(result[i].timestamp).startOf('day').toISOString()
            if (!processedDays[day]) {
              this.statistic.push(result[i])
              processedDays[day] = true
            }
          }

          this.statisticDataLoaded = true
        })
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
